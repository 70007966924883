// nextor.scss

@import '../../assets/css/foundation.scss';

// 1130px 이상 Web
.nextor {
  position: relative;
  width: 100%;
  @include flex(flex, $display-flex-column-no, center, center);

  >.content1to3,
  >.content4,
  >.content5,
  >.content6,
  >.content7,
  >.content8,
  >.content9,
  >.content10 {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, center);
    padding-top: $web-padding-top;
    z-index: 35;
    position: inherit;
    background: #FFF;

    >.content_inner {
      width: $web-width;
      @include flex(flex, $display-flex-column-no, center, center);
    }
  }

  >.content1to3 {
    overflow: hidden;
    padding-top: 0;

    .content1,
    .content2,
    .content3 {
      position: relative;
      width: 100vw;
      height: 100vh;
      @include flex(flex, $display-flex-column-no, center, center);

      >video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      >.textbox {
        z-index: 30;
        position: inherit;
        @include flex(flex, $display-flex-column-no, center, center);

        >.title {
          @include text-style($text-color-dark, $font-size-110, $font-weight-7, $line-height-135, center);
          margin-bottom: 40px;
        }

        >.description {
          @include text-style($text-color-dark-gray, $font-size-22, $font-weight-4, $line-height-35, center);
        }
      }

      &.content1 {
        background: #EDF0F7;
        > .textbox {
          > .title {
            letter-spacing: normal;
          }
        }
        >#mouse-parallax-container {
          z-index: 20;
          position: absolute;
          width: 100%;
          height: 100%;

          >div {
            position: absolute;
          }

          >.center {
            z-index: 40;
            position: absolute;
            top: calc(50% - 22.5%);
            left: calc(50% - 350px);
            width: 30%;
            min-width: 700px;
            height: 45%;
            border: 0.75px solid #2E82CE;
            

            >.ball {
              position: absolute;
              border-radius: 50%;
              width: 14px;
              height: 14px;
              background: radial-gradient(58.82% 58.82% at 27.45% 54.48%, #B8D8FF 0%, #83AAF1 37%, #5986E6 70%);

              &:nth-of-type(1) {
                top: -7px;
                left: -7px;
              }

              &:nth-of-type(2) {
                bottom: -8.75px;
                left: -8.75px;
                width: 17.5px;
                height: 17.5px;
              }

              &:nth-of-type(3) {
                bottom: -7px;
                right: -7px;
              }

              &:nth-of-type(4) {
                top: -7px;
                right: -7px;
              }
            }
          }

          >.yellow_ball {
            top: 60px;
            left: -50px;
            width: 345px;
            height: 325px;
            background: radial-gradient(93.95% 94.01% at 76.27% 36.78%, #FFFF49 6%, #EEE036 39%, #CFA815 91%);
            border-radius: 50%;
            transform: rotate(-50.71deg);
          }

          >.window {
            top: 165px;
            right: -80px;
            @include image-style(550px, 385px, url(../../assets/images/content1_img_window.png) no-repeat 50% 50%, contain);
            @include transition(left, 1s);
          }

          >.purple_ball {
            top: 230px;
            right: 700px;
            width: 42px;
            height: 42px;
            background: radial-gradient(72.99% 72.99% at 43.93% 21.42%, #DBC6FF 6%, #BCAAE7 34%, #7469AF 91%);
            border-radius: 50%;
          }

          >.blue_c {
            top: 300px;
            right: 400px;
            @include image-style(180px, 140px, url(../../assets/images/content1_img_c_blue.png) no-repeat 50% 50%, contain);
          }

          >.pink_c {
            bottom: -20px;
            right: 120px;
            @include image-style(335px, 240px, url(../../assets/images/content1_img_c_pink.png) no-repeat 50% 50%, contain);
          }

          >.hands {
            bottom: 0;
            left: 80px;
            @include image-style(495px, 505px, url(../../assets/images/content1_img_hands.png) no-repeat 50% 50%, contain);
          }

          >.cross1 {
            top: 25%;
            left: 35%;
            @include image-style(20px, 20px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
          }

          >.cross2 {
            bottom: 330px;
            left: 80px;
            @include image-style(50px, 50px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
          }

          >.cross3 {
            top: 40%;
            left: 26%;
            @include image-style(85px, 85px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
          }

          >.cross4 {
            bottom: 30%;
            left: 34%;
            @include image-style(20px, 20px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
          }

          >.cross5 {
            bottom: 24%;
            right: 35%;
            @include image-style(35px, 35px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
          }
        }
      }

      &.content3 {

        >.textbox {
          >.title {
            color: $text-color-white;
          }

          >.description {
            color: $text-color-light-gray;
          }
        }
      }
    }

  }

  >.content4 {
    >.content_inner {
      >.title {
        width: 100%;
        @include text-style($text-color-dark, $font-size-38, $font-weight-7, 53px, left);
        letter-spacing: normal;
        margin-bottom: 20px;
      }

      >.description {
        width: 100%;
        @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
        margin-bottom: 40px;
      }

      >div {
        width: 100%;
        margin-bottom: 40px;

        >div {
          position: relative;
          padding-bottom: 56.25%;
          width: 100%;
          height: 0;

          >iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      >.content4_box {
        width: 100%;
        margin-bottom: 60px;
        @include flex(flex, $display-flex-row-wrap, flex-start, flex-start);

        >.box {
          flex: 1;
          position: inherit;
          width: inherit;
          padding: 0;
          height: inherit;
          margin: 0 32px 32px 0;
          min-width: 500px;

          >.title {
            @include text-style($text-color-dark, $font-size-22, $font-weight-7, 26px, left);
            margin-bottom: 10px;
            letter-spacing: normal;
          }

          >.description {
            @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
          }

          &:nth-of-type(2n) {
            margin-right: 0;
          }

          &:nth-of-type(3),
          &:nth-of-type(4) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  >.content5 {
    >.content_inner {
      >.title {
        width: 100%;
        @include text-style($text-color-dark, $font-size-38, $font-weight-7, 53px, left);
        margin-bottom: 30px;
        letter-spacing: normal;
      }

      >.description {
        width: 100%;
        @include text-style($text-color-dark, $font-size-22, $font-weight-7, 31px, left);
        margin-bottom: 40px;

        >span {
          display: block;
          @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
          margin-top: 10px;
        }
      }

      >.content5_box {
        width: 100%;
        @include flex(flex, $display-flex-row-wrap, flex-start, space-between);

        >.box {
          position: relative;
          width: calc(50% - 20px);
          height: 455px;
          border-radius: 20px;
          margin-bottom: 40px;
          padding: 40px 32px;
          box-sizing: border-box;

          >.title,
          >.description {
            z-index: 10;
            position: absolute;
            left: 32px;
            width: calc(100% - 64px);
          }

          >.title {
            @include text-style($text-color-white, $font-size-22, $font-weight-7, 26px, left);
            top: 40px;
          }

          >.description {
            @include text-style($text-color-light-gray, $font-size-16, $font-weight-4, 26px, left);
            top: 76px;
            letter-spacing: normal;
          }

          // &::after {
          //   position: absolute;
          //   content: "";
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   border-radius: 20px;
          //   background: #000;
          //   opacity: 0.4;
          // }

          &:nth-of-type(1) {
            @include background-image(url(../../assets/images/content5_img1.png) no-repeat 50% 50%, cover);
          }

          &:nth-of-type(2) {
            margin-top: 80px;
            @include background-image(url(../../assets/images/content5_img3.png) no-repeat 50% 50%, cover);
          }

          &:nth-of-type(3) {
            top: -80px;
            margin-bottom: 0;
            @include background-image(url(../../assets/images/content5_img2.png) no-repeat 50% 50%, cover);
          }

          &:nth-of-type(4) {
            margin-bottom: 0;
            @include background-image(url(../../assets/images/content5_img4.png) no-repeat 50% 50%, cover);
          }
        }
      }
    }
  }

  >.content6 {
    >.content_inner {
      >.title {
        width: 100%;
        @include text-style($text-color-dark, $font-size-38, $font-weight-7, 53px, left);
        letter-spacing: 0.01em;
        margin-bottom: 80px;
      }

      >.content6_box1 {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        margin-bottom: 140px;

        >.box {
          @include flex(flex, $display-flex-column-no, center, flex-start);
          flex: 1;
          margin-right: 50px;

          >.image {
            width: 115px;
            height: 115px;
            margin-bottom: 20px;
          }

          >.title {
            @include text-style($text-color-dark, $font-size-22, $font-weight-7, 26px, center);
            margin-bottom: 10px;
            letter-spacing: normal
          }

          >.description {
            @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, center);
          }

          &:nth-of-type(1) {
            >.image {
              @include background-image(url(../../assets/images/content6_img1.png) no-repeat 50% 50%, contain);
            }
          }

          &:nth-of-type(2) {
            >.image {
              @include background-image(url(../../assets/images/content6_img2.png) no-repeat 50% 50%, contain);
            }
          }

          &:nth-of-type(3) {
            >.image {
              @include background-image(url(../../assets/images/content6_img3.png) no-repeat 50% 50%, contain);
            }
          }

          &:nth-of-type(4) {
            margin-right: 0;
            >.image {
              @include background-image(url(../../assets/images/content6_img4.png) no-repeat 50% 50%, contain);
            }
          }
        }
      }

      >.content6_box2 {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);

        >.box {
          width: 100%;
          @include flex(flex, $display-flex-row-no, flex-start, space-between);
          margin-bottom: 60px;

          >.year {
            flex: 1;
            @include text-style($text-color-dark, $font-size-46, $font-weight-7, 55px, inherit);
          }

          >.box_inner {
            flex: 4;

            >.description {
              position: relative;
              @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, inherit);
              padding-left: 15px;
              margin-bottom: 10px;

              &::after {
                position: absolute;
                top: 10px;
                left: 0;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $text-color-dark-gray;
              }
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  >.content7 {
    >.title {
      width: $web-width;
      @include text-style($text-color-dark, $font-size-38, $font-weight-7, 53px, inherit);
      letter-spacing: 0.01em;
      margin-bottom: 40px;
    }

    >.marquee_box {
      position: relative;
      width: 100%;
      height: 40px;
      display: flex;
      flex-flow: row wrap;
      overflow: hidden;

      >.marquee {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-around);
        @include animation-marquee(40s, 0s);

        >.img {
          width: 144px;
          height: 40px;

          &:nth-of-type(1) {
            @include background-image(url(../../assets/images/content7_logo1.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(2) {
            @include background-image(url(../../assets/images/content7_logo2.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(3) {
            @include background-image(url(../../assets/images/content7_logo3.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(4) {
            @include background-image(url(../../assets/images/content7_logo4.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(5) {
            @include background-image(url(../../assets/images/content7_logo5.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(6) {
            @include background-image(url(../../assets/images/content7_logo6.png) no-repeat 50% 50%, contain);
          }

          &:nth-of-type(7) {
            @include background-image(url(../../assets/images/content7_logo7.png) no-repeat 50% 50%, contain);
          }
        }

        &:last-of-type {
          left: 100%;
          @include animation-marquee(40s, 20s);
        }
      }
    }
  }

  >.content8 {
    >.content_inner {
      >.title {
        width: 100%;
        @include text-style($text-color-dark, $font-size-38, $font-weight-7, 23px, center);
        letter-spacing: 0.01em;
        margin-bottom: 40px;
      }

      >.content8_box {
        width: 100%;
        @include flex(flex, $display-flex-row-no, flex-start, space-between);
        margin-bottom: 60px;
        > .no_list {
          flex: 1;
          width: 100%;
          height: 100px;
          @include flex(flex, $display-flex-row-no, center, center);
          > p {
            @include text-style($text-color-dark-gray, 16px, 400, 26px, center);
          }
        }

        >.newsbox {
          flex: 1;
          min-width: 300px;
          margin-right: 60px;
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.sort_tag {
            padding: 2px 12px;
            border-radius: 20px;
            margin-bottom: 10px;

            &.news {
              background: $text-color-news;
            }

            &.notice {
              background: $text-color-notice;
            }

            >p {
              @include text-style($text-color-white, $font-size-12, $font-weight-7, 19px, inherit);
            }
          }

          >.image,
          >.image_none {
            width: 100%;
            height: 200px;
            border-radius: 20px;
            border: 1px solid #E6E6E6;
            margin-bottom: 15px;
            object-fit: cover;
            &.image_none {
              background: url(../../assets/images/default_thumnail.png) no-repeat 50% 50%;
              background-size: cover;
            }
          }

          >.title {
            width: 100%;
            @include text-style($text-color-dark, $font-size-18, $font-weight-7, 29px, inherit);
            margin-bottom: 10px;
          }

          >.date {
            width: 100%;
            @include text-style($text-color-dark-gray, $font-size-14, $font-weight-4, 22px, right);
          }

          &:nth-last-of-type(2) {
            margin-right: 0;
          }

          &:last-of-type {
            display: none;
          }

          &:nth-of-type(1) {
            >.image_none {
              &.image_none {
                background: url(../../assets/images/news1.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
          // &:nth-of-type(2) {
          //   >.image_none {
          //     &.image_none {
          //       background: url(../../assets/images/default_thumnail.png) no-repeat 50% 50%;
          //       background-size: cover;
          //     }
          //   }
          // }
          &:nth-of-type(3) {
            >.image_none {
              &.image_none {
                background: url(../../assets/images/news3.jpg) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  >.content9 {
    >.content_inner {
      >.title {
        width: 100%;
        @include text-style($text-color-dark, 28px, $font-weight-7, 39px, inherit);
        letter-spacing: 0.01em;
        margin-bottom: 20px;
      }

      >.description {
        width: 100%;
        @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, inherit);
        letter-spacing: -0.01em;
        margin-bottom: 40px;
      }

      >.tab_menu {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.title {
          position: relative;
          flex: 1;
          padding: 16px 0;
          @include text-style($text-color-dark-gray, $font-size-14, $font-weight-6, 14px, center);
          cursor: pointer;
          box-sizing: border-box;

          &.on {
            color: $text-color-dark;

            &::after {
              position: absolute;
              left: 0;
              bottom: 0;
              content: "";
              width: 100%;
              height: 2px;
              background: $text-color-dark;
            }
          }
        }
      }

      >.tabbox {
        width: 100%;
        padding: 40px 0 0 0;

        >.description {
          @include text-style($text-color-dark-gray, $font-size-14, $font-weight-4, 22px, center);
          letter-spacing: -0.01em;
          margin-bottom: 40px;
        }

        >.box_inner {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);

          >.box {
            >.image {
              width: 160px;
              height: 160px;
              margin-bottom: 12px;
            }

            >.title {
              width: 100%;
              @include text-style($text-color-dark, $font-size-18, $font-weight-7, 22px, center);
              margin-bottom: 10px;
            }

            >.description {
              @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, center);
            }
          }
        }

        &.index1 {
          >.box_inner {
            >.box {
              &:nth-of-type(1) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img1.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(2) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img2.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(3) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img3.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(4) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img4.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(5) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img5.png) no-repeat 50% 50%, contain);
                }
              }
            }
          }

          >.index2,
          .index3 {
            display: none;
          }
        }

        &.index2 {
          >.box_inner {
            >.box {
              position: relative;

              &:nth-of-type(1) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img6.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(3) {

                >.image {
                  @include background-image(url(../../assets/images/content9_img7.png) no-repeat 50% 50%, contain);
                  width: 100%;
                }
              }

              &:nth-of-type(5) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img8.png) no-repeat 50% 50%, contain);
                }
              }
            }

            >.arrow_right {
              @include image-style(20px, 40px, url(../../assets/images/arrow_right.png) no-repeat 50% 50%, contain);

              &:last-of-type {
                display: none;
              }
            }
          }

          >.index1,
          .index3 {
            display: none;
          }
        }

        &.index3 {
          >.box_inner {
            > div {
              cursor: pointer;
              &:nth-of-type(1) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img9.png) no-repeat 50% 50%, contain);
                }
              }

              &:nth-of-type(2) {
                >.image {
                  @include background-image(url(../../assets/images/content9_img10.png) no-repeat 50% 50%, contain);
                }
              }
            }
            > a {
              cursor: pointer;
              >.image {
                @include background-image(url(../../assets/images/content9_img11.png) no-repeat 50% 50%, contain);
              }
            }
          }

          >.index1,
          .index2 {
            display: none;
          }
        }
      }
    }
  }

  >.content10 {
    padding-bottom: 120px;

    >.title {
      width: 100%;
      @include text-style($text-color-dark, $font-size-38, $font-weight-7, 53px, center);
      letter-spacing: normal;
      margin-bottom: 40px;
    }

    >iframe {
      width: 100%;
      height: 475px;
      margin-bottom: 40px;
    }

    >.box {
      width: $web-width;
      @include flex(flex, $display-flex-column-no, center, center);

      .info {
        width: 100%;
        margin-bottom: 40px;

        >.title {
          @include text-style($text-color-dark, $font-size-22, $font-weight-7, 26px, left);
          margin-bottom: 10px;
          letter-spacing: normal
        }

        >.description {
          @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
        }
      }

      >.box_inner {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.info {
          flex: 1;
          width: inherit;
          margin-bottom: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 1700px),
       screen and (max-height: 900px) {
  .nextor {
    >.content1to3 {
      padding-top: $tablet-padding-top;
      >.content_inner {
        width: $tablet-width;
      }
    }
    >.content1to3 {
      padding-top: 0;

      .content1,
      .content2,
      .content3 {
        position: relative;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;

        >.textbox {
          >.title {
            @include text-style($text-color-dark, $font-size-82, $font-weight-7, $line-height-100, center);
            margin-bottom: 30px;
          }

          >.description {
            @include text-style($text-color-dark-gray, $font-size-18, $font-weight-4, $line-height-26, center);
          }
        }

        &:nth-of-type(1) {
          >#mouse-parallax-container {
            >.center {
              z-index: 30;
              top: calc(50% - 19%);
              left: calc(50% - 275px);
              min-width: 550px;
              height: 38%;

              >.ball {
                width: 10px;
                height: 10px;

                &:nth-of-type(1) {
                  top: -5px;
                  left: -5px;
                }

                &:nth-of-type(2) {
                  bottom: -7px;
                  left: -7px;
                  width: 14px;
                  height: 14px;
                }

                &:nth-of-type(3) {
                  bottom: -5px;
                  right: -5px;
                }

                &:nth-of-type(4) {
                  top: -5px;
                  right: -5px;
                }
              }
            }

            >.yellow_ball {
              top: -5%;
              left: -10%;
            }

            >.purple_ball {
              top: 8%;
              right: 15%;
            }

            >.blue_c {
              top: 15%;
              right: -1%;
            }

            >.pink_c {
              bottom: 2%;
              right: -10%;
            }

            >.hands {
              bottom: 0%;
              left: -18%;
            }

            >.cross1 {
              top: 28%;
              left: 14%;
              @include image-style(16px, 16px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
            }

            >.cross5 {
              bottom: 24%;
              right: 5%;
              @include image-style(30px, 30px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
            }

            >.cross2,
            >.cross3,
            >.cross4,
            >.window {
              display: none;
            }
          }
        }

        &:nth-of-type(3) {
          >.textbox {
            >.title {
              color: $text-color-white;
            }

            >.description {
              color: $text-color-light-gray;
            }
          }
        }
      }
    }
  }
}

// 1130px 이하 Tablet 
@media screen and (max-width: 1130px),
       screen and (max-height: 750px) {
  .nextor {

    >.content4,
    >.content5,
    >.content6,
    >.content7,
    >.content8,
    >.content9,
    >.content10 {
      padding-top: $tablet-padding-top;

      >.content_inner {
        width: $tablet-width;
      }
    }

    >.content4 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 38px, left);
        }

        >.description {
          @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
        }

        >.content4_box {
          >.box {
            min-width: 335px;

            >.title {
              @include text-style($text-color-dark, $font-size-22, $font-weight-7, 26px, left);
            }

            >.description {
              @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
            }
          }
        }
      }
    }

    >.content5 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 53px, left);
        }

        >.description {
          @include text-style($text-color-dark, $font-size-16, $font-weight-7, 22px, left);

          >span {
            @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 25px, left);
          }
        }

        >.content5_box {
          >.box {
            height: 285px;
          }
        }
      }
    }

    >.content6 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 40px, left);
        }

        >.content6_box1 {
          @include flex(flex, $display-flex-row-wrap, center, space-between);

          >.box {
            width: calc(50% - 100px);
            flex: inherit;
            margin-right: 0;

            >.image {
              width: 130px;
              height: 130px;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 75px;
            }
          }
        }

        >.content6_box2 {
          >.box {
            width: 100%;
            @include flex(flex, $display-flex-row-no, flex-start, space-between);
            margin-bottom: 60px;

            >.year {
              @include text-style($text-color-dark, $font-size-28, $font-weight-7, 34px, inherit);
            }
          }
        }
      }
    }

    >.content7 {
      width: 100%;
      overflow: hidden;

      >.title {
        width: $tablet-width;
        @include text-style($text-color-dark, $font-size-28, $font-weight-7, 38px, inherit);
      }

      >.marquee_box {
        width: 150%;
      }
    }

    >.content8 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 38px, center);
        }

        >.content8_box {
          @include flex(flex, $display-flex-row-wrap, flex-start, space-between);

          >.newsbox {
            margin-right: 80px;
            @include flex(flex, $display-flex-column-no, flex-start, flex-start);

            &:nth-of-type(2n) {
              margin-right: 0;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 80px;
            }
            
            &:nth-last-of-type(2) {
              margin-right: 80px;
            }
  
            &:last-of-type {
              display: inherit;
            }
          }
        }
      }
    }

    >.content9 {
      >.content_inner {
        >.tabbox {
          >.box_inner {
            @include flex(flex, $display-flex-row-wrap, center, space-between);

            >.box {
              >.description {
                @include text-style($text-color-dark-gray, $font-size-14, $font-weight-4, 21px, center);
              }
            }
          }

          &.index1 {
            >.box_inner {
              >.box {

                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                  margin-bottom: 60px;
                }
              }
            }
          }

          &.index2 {
            >.box_inner {
              >.arrow_right {
                @include image-style(8px, 18px, url(../../assets/images/arrow_right.png) no-repeat 50% 50%, contain);
              }
            }
          }
        }
      }
    }

    >.content10 {
      >.title {
        @include text-style($text-color-dark, $font-size-28, $font-weight-7, 53px, center);
      }

      >iframe {
        height: 510px;
      }

      >.box {
        width: $tablet-width;
      }
    }
  }
}

// 704px 이하 Mobile
@media screen and (max-width: 704px),
       screen and (max-height: 500px) {
  .nextor {

    >.content1to3,
    >.content4,
    >.content5,
    >.content6,
    >.content7,
    >.content8,
    >.content9,
    >.content10 {
      >.content_inner {
        width: $mobile-width;
      }
    }

    >.content1to3 {

      .content1,
      .content2,
      .content3 {
        >.textbox {
          >.title {
            @include text-style($text-color-dark, $font-size-40, $font-weight-7, 48px, center);
            margin-bottom: 20px;
          }

          >.description {
            @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 24px, center);
          }
        }

        &:nth-of-type(1) {
          >#mouse-parallax-container {
            >.center {
              z-index: 30;
              top: calc(50% - 19%);
              left: calc(50% - 140px);
              width: 20%;
              min-width: 280px;
              height: 38%;

              >.ball {
                width: 7px;
                height: 7px;

                &:nth-of-type(1) {
                  top: -3.5px;
                  left: -3.5px;
                }

                &:nth-of-type(2) {
                  bottom: -4.5px;
                  left: -4.5px;
                  width: 9px;
                  height: 9px;
                }

                &:nth-of-type(3) {
                  bottom: -3.5px;
                  right: -3.5px;
                }

                &:nth-of-type(4) {
                  top: -3.5px;
                  right: -3.5px;
                }
              }
            }

            >.yellow_ball {
              width: 260px;
              height: 260px;
              top: -5%;
              left: -25%;
            }

            >.purple_ball {
              width: 30px;
              height: 30px;
              top: 8%;
              right: 17%;
            }

            >.blue_c {
              width: 125px;
              height: 98px;
              top: 10%;
              right: -5%;
            }

            >.pink_c {
              width: 178px;
              height: 130px;
              bottom: 2%;
              right: -10%;
            }

            >.hands {
              width: 256px;
              height: 260px;
              bottom: 0%;
              left: -5%;
            }

            >.cross1 {
              top: 28%;
              left: 14%;
              @include image-style(10px, 10px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
            }

            >.cross5 {
              bottom: 24%;
              right: 5%;
              @include image-style(20px, 20px, url(../../assets/images/content1_img_cross.png) no-repeat 50% 50%, contain);
            }

            >.cross2,
            >.cross3,
            >.cross4,
            >.window {
              display: none;
            }
          }
        }
      }
    }

    >.content4 {
      >.content_inner {
        >.content4_box {
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.box {
            min-width: inherit;
            margin: 0 0 32px 0;

            &:nth-of-type(3) {
              margin-bottom: 32px
            }

            &:last-of-type {
              margin-bottom: 0
            }

            >.title {
              @include text-style($text-color-dark, $font-size-22, $font-weight-7, 26px, left);
            }

            >.description {
              @include text-style($text-color-dark-gray, $font-size-16, $font-weight-4, 26px, left);
            }
          }
        }

        >.button {
          width: 100% !important;
          padding: 14px 0 !important;
        }
      }
    }

    >.content5 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 39px, left);
        }

        >.content5_box {
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.box {
            width: 100%;
            height: 260px;
            margin-top: 0 !important;
            margin-bottom: 24px !important;
            top: inherit !important;

            &:last-of-type {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    >.content6 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 39px, left);
        }

        >.content6_box1 {
          @include flex(flex, $display-flex-column-no, center, space-between);

          >.box {
            width: 100%;
            margin-bottom: 80px !important;

            &:last-of-type {
              margin-bottom: 0 !important;
            }

            >.image {
              width: 120px;
              height: 120px;
            }

            >.title {
              width: 100%;
            }
          }
        }

        >.content6_box2 {
          >.box {
            width: 100%;
            @include flex(flex, $display-flex-column-no, flex-start, space-between);
            margin-bottom: 60px;

            >.year {
              @include text-style($text-color-dark, $font-size-28, $font-weight-7, 34px, inherit);
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    >.content7 {
      width: 100%;
      overflow: hidden;

      >.title {
        width: $mobile-width;
        @include text-style($text-color-dark, $font-size-28, $font-weight-7, 38px, inherit);
      }

      >.marquee_box {
        width: 300%;
      }
    }

    >.content8 {
      >.content_inner {
        >.title {
          @include text-style($text-color-dark, $font-size-28, $font-weight-7, 38px, center);
        }

        >.content8_box {
          @include flex(flex, $display-flex-column-no, flex-start, space-between);

          >.newsbox {
            margin-right: 0;
            @include flex(flex, $display-flex-column-no, flex-start, flex-start);
            margin-bottom: 40px !important;

            &:nth-of-type(2n) {
              margin-right: 0;
            }

            &:last-of-type {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    >.content9 {
      >.content_inner {
        >.tabbox {
          >.box_inner {
            @include flex(flex, $display-flex-column-no, center, space-between);

            >.box {
              margin-bottom: 70px;

              // &:last-of-type {
              //   margin-bottom: 0;
              // }

              >.title {
                margin-bottom: 0;
              }

              >.description {
                @include text-style($text-color-dark-gray, $font-size-14, $font-weight-4, 21px, center);
              }
            }
          }

          &.index2 {
            >.box_inner {
              >.box {
                margin-bottom: 35px;

                &:nth-of-type(5) {
                  margin-bottom: 0;
                }
              }

              >.arrow_right {
                @include image-style(8px, 18px, url(../../assets/images/arrow_right.png) no-repeat 50% 50%, contain);
                transform: rotate(90deg);
                margin-bottom: 35px;
              }
            }
          }
        }
      }
    }

    >.content10 {
      >.title {
        @include text-style($text-color-dark, $font-size-28, $font-weight-7, 53px, center);
      }

      >iframe {
        height: 300px;
      }

      >.box {
        width: $mobile-width;

        >.box_inner {
          @include flex(flex, $display-flex-column-no, center, center);

          >.info {
            margin-bottom: 28px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
